import {debounce} from 'throttle-debounce';
window.debounce = debounce;

window._ = require('lodash');

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
    require('bootstrap-datepicker');
} catch (e) {}

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

//require('jquery-throttle-debounce');
require('datatables.net');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-fixedcolumns-bs4');
require('datatables.net-fixedheader-bs4');
require('datatables.net-keytable-bs4');
require('datatables.net-responsive-bs4');
require('datatables.net-scroller-bs4');
require('datatables.net-select-bs4');
require('./buttons.server-side');

require('select2');
require('./select2.multi-checkboxes');
require('mark.js/dist/jquery.mark');

// window.datepicker = require('bootstrap-datepicker');
window.Msg = require('bootstrap-msg/dist/js/bootstrap-msg')
window.PDFObject = require('pdfobject');
window.moment = require('moment');

//window.DataTable = require('datatables.net').default;
